var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex-center",
    attrs: {
      "id": "search-source-select"
    }
  }, [_c('img', {
    staticClass: "mr-25",
    attrs: {
      "src": require("@icons/plane.svg")
    }
  }), _c('v-select', {
    staticClass: "w-100 remove_action",
    attrs: {
      "value": _vm.valueProp,
      "multiple": "",
      "options": _vm.carriersOptions,
      "label": "key",
      "disabled": _vm.isDisabled,
      "append-to-body": "",
      "reduce": function reduce(option) {
        var _option$key;
        return option === null || option === void 0 ? void 0 : (_option$key = option.key) === null || _option$key === void 0 ? void 0 : _option$key.toUpperCase();
      },
      "calculate-position": _vm.withPopper,
      "placeholder": _vm.$t('flight.carriersSearchPlaceholder'),
      "filter": _vm.filterHandle
    },
    on: {
      "input": _vm.handleUpdate,
      "option:deselecting": _vm.handleDeselectingCarrier
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(data) {
        var _data$key;
        return [_c('span', {
          staticClass: "text-body font-weight-bold"
        }, [_vm._v(_vm._s(data === null || data === void 0 ? void 0 : (_data$key = data.key) === null || _data$key === void 0 ? void 0 : _data$key.toUpperCase()))])];
      }
    }, {
      key: "option",
      fn: function fn(data) {
        var _data$key2;
        return [_c('span', {}, [_c('span', {
          staticClass: "font-weight-bolder mr-25"
        }, [_vm._v(_vm._s(data === null || data === void 0 ? void 0 : (_data$key2 = data.key) === null || _data$key2 === void 0 ? void 0 : _data$key2.toUpperCase()))]), data.value ? _c('small', [_vm._v("(" + _vm._s(data.value) + ")")]) : _vm._e()])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }